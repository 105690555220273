import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VCard,{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c(VToolbar,{staticClass:"pl-10 pt-5 mb-5",attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,[_c(VSelect,{attrs:{"label":_vm.$store.getters.translate('select_scheme'),"items":_vm.$lodash.sortBy(_vm.schemes, 'name'),"return-object":true,"item-text":"name","item-value":"id","clearable":""},on:{"change":_vm.getScheme},model:{value:(_vm.selected_scheme),callback:function ($$v) {_vm.selected_scheme=$$v},expression:"selected_scheme"}})],1)],1),_c(VDivider),_c(VCard,{staticClass:"ml-5 mt-5 mr-5 mb-5"},[_c('FullCalendar',{ref:"full_calendar",attrs:{"plugins":_vm.plugins,"options":_vm.calendarOptions}})],1)],1),_c('create-exam',{ref:"create_exam",attrs:{"modal_name":"create_exam","disabled":_vm.disabled}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }