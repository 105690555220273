<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar class="pl-10 pt-5 mb-5" dense flat>
          <v-toolbar-title>
            <v-select :label="$store.getters.translate('select_scheme')"
                      v-model="selected_scheme"
                      :items="$lodash.sortBy(schemes, 'name')"
                      :return-object="true"
                      item-text="name"
                      item-value="id"
                      clearable
                      @change="getScheme"/>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider/>
        <v-card class="ml-5 mt-5 mr-5 mb-5">
          <FullCalendar ref="full_calendar" :plugins="plugins" :options="calendarOptions" />
        </v-card>
      </v-card>
      <create-exam modal_name="create_exam" ref="create_exam" :disabled="disabled"/>
    </v-col>
  </v-row>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import moment from "moment";
import CreateExam from "../exams/Create";

export default {
  components: {
    CreateExam,
    FullCalendar
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("planner"),
        name: "planner",
        model: "planner",
      },
      calendarOptions: {
        schedulerLicenseKey:'0428499722-fcs-1583088865',
        plugins: [ dayGridPlugin, interactionPlugin, resourceTimelinePlugin ],
        headerToolbar: {
          start: 'today prev,next',
          center: 'title',
          end: 'resourceTimelineWeek, resourceTimelineMonth'
        },
        initialView: 'resourceTimelineMonth',
        views: {
          resourceTimelineWeek: {
            slotDuration: { days: 1 }
          }
        },
        resourceGroupField: 'location',
        resourceAreaColumns: [
          {
            field: 'title',
            headerContent: 'Location',
          },
        ],
        resourceAreaWidth: "300px",
        dateClick: this.handleDateClick,
        resources: this.loadResources,
        events: this.loadEvents,
      },
      loading: false,
      disabled: false,
      future_events: [],
      schemes: [],
      locations: [],
      rooms: [],
      selected_scheme: null,
      selected_location: null,
      selected_room: null,
    }
  },
  created() {
    this.getSchemes();
  },
  methods: {
    getSchemes() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/schemes?source=site")
          .then((response) => {
            this.loading = false;
            this.schemes = response.data.data;
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 403) {
              this.$toasted.error(this.$store.getters.translate('no_access'))
            } else {
              this.$toasted.error(error);
            }
          });
    },
    getScheme(scheme) {
      if(scheme) {
        this.loading = true;
        this.$http
            .get(this.$store.getters.appUrl + "v2/schemes/" + scheme.id)
            .then((response) => {
              this.loading = false;
              this.selected_scheme = response.data;
              this.$refs.full_calendar.getApi().refetchResources();
              this.$refs.full_calendar.getApi().refetchEvents();
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
      else {
        this.selected_scheme = null;
        this.$refs.full_calendar.getApi().refetchResources();
        this.$refs.full_calendar.getApi().refetchEvents();
      }
    },
    handleDateClick(info) {
      if(moment(info.dateStr).isoWeekday() === 6 || moment(info.dateStr).isoWeekday() === 7) {
        this.$toasted.error(this.$store.getters.translate("please_select_working_day"));
      }
      else if(moment(info.dateStr).add(1, 'days') < moment.now()) {
        this.$toasted.error(this.$store.getters.translate("please_select_future_date"));
      }
      else if (!this.selected_scheme) {
        this.$toasted.error(this.$store.getters.translate("please_select_scheme"));
      }
      else {
        //get selected location
        this.selected_location = null;
        let ids = info.resource._resource.id.split("_");
        this.locations.forEach((location) => {
          if (location.id === parseInt(ids[0])) {
            this.selected_location = location;
          }
        });
        //get selected room
        this.selected_room = null;
        this.rooms.forEach((room) => {
          if (room.id === parseInt(ids[1])) {
            this.selected_room = room;
          }
        });
        if(!this.selected_room) {
          this.$toasted.error(this.$store.getters.translate("please_select_room"));
        }
        else {
          this.$refs.create_exam.record.re_examination = false;
          this.$refs.create_exam.record.examination_date = moment(info.dateStr, "YYYY-MM-DD").format('DD-MM-YYYY');
          this.$refs.create_exam.record.location_id = this.selected_location.id;
          this.$refs.create_exam.record.room_id = this.selected_room.id;
          this.$refs.create_exam.locations = this.locations;
          this.$refs.create_exam.rooms = this.rooms;
          this.$refs.create_exam.selected_location = this.selected_location;
          this.$refs.create_exam.selected_room = this.selected_room;
          //this.$refs.create_exam.getRooms();
          this.$refs.create_exam.addScheme(this.selected_scheme);
          this.$refs.create_exam.openModal('create_exam');
        }
      }
    },
    loadResources(fetchInfo, successCallback) {
      let resources = [];
      if(this.selected_scheme) {
        var added_locations = [];
        var added_rooms = [];
        this.locations = [];
        this.rooms = [];
        this.selected_scheme.assessors.forEach((assessor) => {
          //NO NEED TO CHECK DOCENT BECAUSE ITV DOES NOT HAVE THEM
          //if(assessor.type.includes("docent")) {
            assessor.rooms.forEach((room) => {
              if(room.location.show_in_planner) {
                if (!added_locations.includes(room.location.id)) {
                  added_locations.push(room.location.id);
                  this.locations.push(room.location);
                }
                if (!added_rooms.includes(room.id)) {
                  added_rooms.push(room.id);
                  this.rooms.push(room);
                }
              }
            });
          //}
        });
        this.locations.forEach((location) => {
          this.rooms.forEach((room) => {
            if(room.location.id === location.id) {
              resources.push({"id": location.id + "_" + room.id, "location": location.name, "title": room.name});
            }
          });
          resources.push({"id": location.id + "_999999", "location": location.name, "title": "Ruimte niet geselecteerd"});
        });
        successCallback(resources);
      }
      else {
        this.$http
            .get(this.$store.getters.appUrl + "v2/locations-with-rooms")
            .then((response) => {
              response.data.forEach((location) => {
                if(location.show_in_planner) {
                  location.rooms.forEach((room) => {
                    resources.push({"id": location.id + "_" + room.id, "location": location.name, "title": room.name});
                  });
                  resources.push({"id": location.id + "_999999", "location": location.name, "title": "Ruimte niet geselecteerd"});
                }
              });
              successCallback(resources);
            })
            .catch((error) => {
              if (error.response.status === 403) {
                this.$toasted.error(this.$store.getters.translate('no_access'))
              } else {
                this.$toasted.error(error);
              }
            });
      }
    },
    loadEvents(fetchInfo, successCallback) {
      let events = [];
      let params = '';
      if(this.selected_scheme) {
        params = "&scheme_id=" + this.selected_scheme.id;
      }
      this.$http
          .get(this.$store.getters.appUrl + "v2/exams?source=site" + params)
          .then((response) => {
            response.data.data.forEach((exam) => {
              var color = "blue";
              if (moment(exam.examination_date) < moment.now()) {
                color = "green";
              } else if (exam.re_examination) {
                color = "darkblue";
              }
              var resourceId = exam.location_id + "_999999";
              if(exam.room_id) {
                resourceId = exam.location_id + "_" + exam.room_id;
              }
              events.push({
                "id": exam.id,
                "resourceId": resourceId,
                "allDay": true,
                "start": moment(exam.examination_date).toDate(),
                "end": moment(exam.examination_date).toDate(),
                "title": exam.name,
                "url": "/exams/" + exam.id,
                "color": color
              });
              if (moment(exam.examination_date).add(1, 'days') > moment.now()) {
                this.future_events.push({
                  "id": exam.id,
                  "resourceId": resourceId,
                  "start": moment(exam.examination_date).format('DD-MM-YYYY'),
                  "end": moment(exam.examination_date).format('DD-MM-YYYY')
                });
              }
            });
            successCallback(events);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.$toasted.error(this.$store.getters.translate('no_access'))
            } else {
              this.$toasted.error(error);
            }
          });
    },
  },
}
</script>

<style>
.fc-day-sun, .fc-day-sat {
  background-color: #f2f2f2;
}
</style>